
export function hookVideoEmbeds() {
	const videos = document.querySelectorAll( '[data-video-embed]' );
	if ( !videos[0] ) {
		return;
	}

	for ( let i = 0; i < videos.length; i++ ) {
		addPlayListener( videos[i] );
	}

	function addPlayListener( el ) {
		const btn = el.querySelector( '.video__poster' );
		btn.addEventListener( 'click', playVideo( el ), false );
	}
	function playVideo( el ) {
		return () => {
			const video = el.querySelector( 'iframe' );
			video.src += '?autoplay=1';
			el.classList.add( 'video-loaded' );
		};
	}
}
