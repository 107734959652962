/* global Chart */
import 'chart.js/dist/Chart.min.js';
import { colors } from '../../variables.yml';
import { getWindowWidth } from '../helpers.js';

const dataset_curvature_map = {
	smooth: 0.5,
	straight: 0
};

export function buildChart( container ) {
	const canvas = container.querySelector( '.chart__visual' );
	const dataset_count = parseInt( container.getAttribute( 'data-chart-dataset-count' ) );
	const stack_data = container.hasAttribute( 'data-chart-stacked' );
	const dataset_types = Array.from( container.querySelectorAll( '[data-chart-dataset-type]' ) );

	let dataset_type_line_count = 0;
	for ( let i = 0; i < dataset_types.length; i++ ) {
		if ( dataset_types[i].getAttribute( 'data-chart-dataset-type' ) === 'line' ) {
			dataset_type_line_count++;
		}
	}

	const opts = {
		responsive: true,
		legend: {
			display: container.hasAttribute( 'data-chart-legend' ),
			labels: {
				fontSize: 0,
				padding: 0
			}
		},
		legendCallback: ( chart ) => {
			const items = chart.legend.legendItems;
			if ( typeof items !== 'undefined' && items[0].text ) {
				let str = '<ul class="legend chart__legend">';
				for ( let i = items.length - 1; i >= 0; i-- ) {
					str += '<li class="legend__li legend__li--m"><span class="legend__label legend__label--m" style="background-color:' + items[i].fillStyle + '"></span><p class="legend__text">' + items[i].text + '</p></li>';
				}
				return ( str + '</ul>' );
			}
			return null;
		},
		scales: {
			xAxes: [{
				display: true,
				gridLines: {
					display: ( dataset_type_line_count > 0 ), // only display if at least one line type
					drawBorder: false
				},
				stacked: stack_data,
				scaleLabel: getScaleLabelConfig( container.getAttribute( 'data-axes-label-x' ) ),
				categoryPercentage: 0.96,
				barPercentage: 1,
				ticks: {
					padding: 5
				}
			}],
			yAxes: [{
				display: true,
				gridLines: {
					borderDash: [ 1, 1 ],
					color: colors.lightGrey,
					drawBorder: false,
					zeroLineBorderDash: [ 1, 1 ],
					zeroLineWidth: 0
				},
				stacked: stack_data,
				scaleLabel: getScaleLabelConfig( container.getAttribute( 'data-axes-label-y' ) ),
				ticks: {
					padding: 8
				}
			}]
		},
		title: {
			display: false
		},
		tooltips: getTooltipConfig()
	};

	return {
		chartCanvas: canvas,
		chartConfig: {
			type: ( dataset_type_line_count === dataset_count ? 'line' : 'bar' ),
			data: createChartData( container.querySelector( '.chart__table' ), dataset_count ),
			options: opts
		},
		container: container
	};

	function getTooltipConfig() {
		return {
			enabled: false,
			mode: 'index',
			position: 'nearest',
			intersect: false, // required for tooltip to work on line types
			custom: ( tooltip_model ) => {
				let tooltip_el = document.getElementById( 'chartjs-tooltip' );
				if ( !tooltip_el ) {
					tooltip_el = document.createElement( 'div' );
					tooltip_el.id = 'chartjs-tooltip';
					tooltip_el.className = 'tooltip legend';
					document.body.appendChild( tooltip_el );
					document.addEventListener( 'mousemove', adjustTooltip( tooltip_el ) );
					document.addEventListener( 'touchstart', adjustTooltip( tooltip_el, true ) );
					document.addEventListener( 'touchmove', adjustTooltip( tooltip_el, true ) );
					document.addEventListener( 'touchend', hideTooltip( tooltip_el, true ) );
				}

				if ( tooltip_model.body ) {
					const title_lines = tooltip_model.title || [];
					const body_lines = tooltip_model.body.map( getBody );

					let innerHtml = '';
					for ( let i = 0; i < title_lines.length; i++ ) {
						innerHtml += '<p class="iota h2">' + title_lines[i] + '</p>';
					}

					innerHtml += '<ul>';
					for ( let i = body_lines.length - 1; i >= 0; i-- ) {
						const colors = tooltip_model.labelColors[i];
						const span = '<span class="legend__label" style="background-color:' + colors.backgroundColor + '"></span>';
						innerHtml += '<li class="legend__li">' + span + '<p class="legend__text">' + body_lines[i] + '</p></li>';
					}
					innerHtml += '</ul>';

					tooltip_el.innerHTML = innerHtml;
				}

				function getBody( body_item ) {
					return body_item.lines;
				}
			}
		};
	}

	function getScaleLabelConfig( str ) {
		return {
			display: ( str.length > 0 ),
			fontSize: 14,
			labelString: str
		};
	}

	function createChartData( el, dataset_count ) {
		const all_dataset_values = Array.from( el.querySelectorAll( '[data-chart-dataset-id]' ) );
		const dataset_arr = [];
		for ( let i = dataset_count - 1; i >= 0; i-- ) {
			const dataset_items = all_dataset_values.filter( item => parseInt( item.getAttribute( 'data-chart-dataset-id' ) ) === i );
			dataset_arr.push( createDataset( dataset_items ) );
		}
		const x_axis_labels = getArrayFromAttribute( el, 'data-chart-x-value' );

		return {
			labels: x_axis_labels,
			datasets: dataset_arr
		};
	}
	function createDataset( values ) {
		const label_attr = 'data-chart-dataset-label';
		const value_attr = 'data-chart-dataset-value';
		const obj = {
			label: '',
			backgroundColor: colors['navy'],
			color: colors['navy'],
			// hoverBackgroundColor: colour.hover
			data: [],
			type: 'bar'
		};

		for ( let i = 0; i < values.length; i++ ) {
			const val = values[i];
			if ( val.hasAttribute( label_attr ) ) {
				const type = val.getAttribute( 'data-chart-dataset-type' );
				const colour_value = colors[val.getAttribute( 'data-chart-dataset-color' )] || null;
				if ( colour_value ) {
					obj.backgroundColor = colour_value;
					obj.borderColor = colour_value;
				}
				obj.label = val.getAttribute( label_attr );
				obj.type = type;
				if ( type === 'line' ) {
					obj.fill = ( val.getAttribute( 'data-chart-dataset-line-style' ) === 'filled' );
					obj.lineTension = dataset_curvature_map[val.getAttribute( 'data-chart-dataset-line-curvature' )];
					obj.pointRadius = 0;
				}

			} else if ( val.hasAttribute( value_attr ) ) {
				obj.data.push( val.getAttribute( value_attr ) );
			}
		}

		return obj;
	}

	function getArrayFromAttribute( container, attribute ) {
		const node_list = container.querySelectorAll( '[' + attribute + ']' );
		const arr = [];
		for ( let i = 0; i < node_list.length; i++ ) {
			arr.push( node_list[i].getAttribute( attribute ) );
		}
		return arr;
	}

	function adjustTooltip( tooltip, is_touch = false ) {
		return ( e ) => {
			if ( e.target.classList.contains( 'chartjs-render-monitor' ) ) {
				const window_width = getWindowWidth();
				let dx = e.clientX;
				let dy = e.clientY;
				tooltip.style.opacity = 1;
				if ( window_width < 759 && is_touch ) {
					tooltip.classList.remove( 'tooltip--mouse-view' );
					dx = 20;
					dy = e.target.getBoundingClientRect().top + e.target.offsetHeight;
				} else {
					tooltip.classList.add( 'tooltip--mouse-view' );
				}
				tooltip.style.left = window.pageXOffset + dx + 'px';
				tooltip.style.top = window.pageYOffset + dy + 'px';
				return;
			}
			hideTooltip( tooltip );
		};
	}
	function hideTooltip( el ) {
		el.style.opacity = 0;
	}
}

export function initChart( obj ) {
	const legend_position = obj.container.getAttribute( 'data-chart-legend-pos' );
	const chart = new Chart( obj.chartCanvas.getContext( '2d' ), obj.chartConfig );
	const legend_html_str = chart.generateLegend();
	obj.container.classList.remove( 'chart--loading' );
	if ( legend_html_str ) {
		obj.chartCanvas.insertAdjacentHTML( legend_position === 'bottom' ? 'afterEnd' : 'beforeBegin', legend_html_str );
		chart.legend.options.display = false;
	}
}
