import axios from 'axios/lib/axios.js';

export function hookAjaxForm( el, trigger, cb_success, cb_loading = () => {}, cb_error = () => {} ) {
	// NB ie11+
	if ( !el.action.value || typeof FormData === 'undefined' || typeof Promise === 'undefined' ) {
		return;
	}

	trigger.addEventListener( 'click', ( e ) => {
		e.preventDefault();
		cb_loading();
		axios( {
			method: el.method,
			url: el.action.value,
			data: new FormData( el ),
			xsrfCookieName: 'CRAFT_CSRF_TOKEN'
		} ).then( cb_success() ).catch( cb_error() );
	} );
}
