/* global Chart */

// src/main.js
// framework libs
import './vendor/array-from-polyfill.js';
import Uncloak from './vendor/uncloak.js';

// helpers & variables
import { breakpoints, colors } from '../variables.yml';
import { getWindowWidth, handleBodyClasses } from './helpers.js';

// modules
import { buildSlider } from './modules/build-slider.js';
import { hookVideoEmbeds } from './modules/hook-video-embeds.js';
import { initSearch } from './modules/init-search.js';
import { initAnnotations } from './modules/init-annotations.js';
import { buildChart, initChart } from './modules/build-chart.js';
import { hookAjaxForm } from './modules/hook-ajax-form.js';
import { initViewer } from './modules/init-viewer.js';

hookVideoEmbeds();
initSearch();
initAnnotations();

window.charts = [];
const uncloak_callbacks = {
	uncloak: []
};

( () => {
	// charts
	const els = document.querySelectorAll( '.chart' );
	if ( !els[0] ) {
		return;
	}

	Chart.defaults.global.defaultFontFamily = 'DIN, sans-serif';
	Chart.defaults.global.defaultFontColor = colors.black;
	Chart.defaults.global.defaultFontSize = 12;
	Chart.defaults.global.spanGaps = true;

	for ( let i = 0; i < els.length; i++ ) {
		els[i].setAttribute( 'data-chart-id', i );
		window.charts.push( buildChart( els[i] ) );
	}

	uncloak_callbacks.uncloak.push( ( item ) => {
		if ( item.el.hasAttribute( 'data-chart-id' ) ) {
			const id = item.el.getAttribute( 'data-chart-id' );
			initChart( window.charts[id] );
		}
	} );
} )();

( () => {
	const form = document.querySelector( '[data-form-close-welcome]' );
	if ( !form ) {
		return;
	}

	hookAjaxForm( form, form.querySelector( '.welcome__close' ), () => {
		document.body.classList.add( 'user-closed-welcome' );
	} );
} )();

( () => {
	const form = document.querySelector( '[data-form-toggle-pin]' );
	if ( !form ) {
		return;
	}

	const btn = form.querySelector( '.icon-title--btn' );
	hookAjaxForm( form, btn, () => {
		btn.classList.toggle( 'icon-title--toggled' );
	} );
} )();

( () => {
	const links = document.querySelectorAll( '.lightboxable' );
	if ( !links[0] ) {
		return;
	}

	for ( let i = 0; i < links.length; i++ ) {
		initViewer( links[i] );
	}

} )();

( () => {
	const sliders = document.querySelectorAll( '.base-slider' );
	if ( !sliders[0] ) {
		return;
	}

	for ( let i = 0; i < sliders.length; i++ ) {
		buildSlider( sliders[i], breakpoints );
	}
} )();

( () => {
	// toggles
	const els = document.querySelectorAll( '[data-toggle-target]' );
	if ( !els[0] ) {
		return;
	}

	for ( let i = 0; i < els.length; i++ ) {
		const el = els[i];
		const target = document.querySelector( el.getAttribute( 'data-toggle-target' ) );
		const toggle_class = target.getAttribute( 'data-toggle-class' ) || 'toggled';

		el.addEventListener( 'click', () => {
			target.classList.toggle( toggle_class );
		} );
	}
} )();

( () => {
	// Nav
	const btn = document.querySelector( '.header__btn--nav' );
	if ( !btn ) {
		return;
	}
	btn.addEventListener( 'click', () => {
		handleBodyClasses( 'body-nav-open', 'body-search-open' );
	} );
} )();

( () => {
	// Switcher
	const switcher = document.querySelector( '.header__switcher-button' );
	if ( !switcher ) {
		return;
	}
	switcher.addEventListener( 'click', ( e ) => {
		e.currentTarget.parentElement.classList.toggle( 'switched' );
	} );
} )();

( () => {
	// Nav subtitles
	const els = document.querySelectorAll( '.nav__subtitle--btn' );
	if ( !els[0] ) {
		return;
	}

	for ( let i = 0; i < els.length; i++ ) {
		hook( els[i] );
	}

	function hook( el ) {
		const nav_lists = el.parentNode.querySelectorAll( '.nav__list--expands' );
		el.addEventListener( 'click', () => {
			for ( let i = 0; i < nav_lists.length; i++ ) {
				nav_lists[i].classList.toggle( 'nav__list--expanded' );
			}
		} );
	}
} )();

( () => {
	// Search
	const search_input = document.querySelector( '.header__search input' );
	const search_btn = document.querySelector( '.header__btn--search' );
	if ( !search_input || !search_btn ) {
		return;
	}
	search_btn.addEventListener( 'click', () => {
		handleBodyClasses( 'body-search-open', 'body-nav-open' );
		document.body.classList.contains( 'body-search-open' ) ? search_input.focus() : search_input.blur();
	} );
} )();

( () => {
	// Sidebar subnav btns
	const els = document.querySelectorAll( '.sidebar__link--btn' );
	if ( !els[0] ) {
		return;
	}

	for ( let i = 0; i < els.length; i++ ) {
		addHandleToggle( els[i] );
	}

	function addHandleToggle( el ) {
		el.addEventListener( 'click', () => {
			el.parentNode.classList.toggle( 'sidebar__item--expanded' );
		} );
	}
} )();

( () => {
	// Nav
	const sidebar_toggle = document.querySelector( '.sidebar__btn' );
	if ( !sidebar_toggle ) {
		return;
	}

	const page_inner = document.querySelector( '.page__inner' );
	page_inner.addEventListener( 'click', () => {
		if ( getWindowWidth() <= breakpoints.d.width ) {
			hideSidebar();
		}
	} );
	sidebar_toggle.addEventListener( 'click', () => {
		document.body.classList.toggle( 'body-sidebar-hide' );
		document.body.classList.toggle( 'body-sidebar-show' );
	} );

	function hideSidebar() {
		if ( document.body.classList.contains( 'body-sidebar-show' ) ) {
			document.body.classList.add( 'body-sidebar-hide' );
			document.body.classList.remove( 'body-sidebar-show' );
		}
	}
} )();

( () => {
	window.uncloakInstance = new Uncloak( {
		callbacks: uncloak_callbacks
	} );
} )();

( () => {
	// https://gomakethings.com/how-to-prevent-fullscreen-web-apps-from-opening-links-in-a-new-tab-on-ios/
	function handleWindowClick( event ) {
		// Only run for iOS full screen apps.
		if ( ( 'standalone' in window.navigator ) && window.navigator.standalone ) {
			// Only run if link is an anchor and points to the current page.
			if (
				event.target.tagName.toLowerCase() !== 'a'
				|| event.target.hostname !== window.location.hostname
				|| event.target.pathname !== window.location.pathname
				|| !/#/.test( event.target.href )
			) {
				return;
			}
			event.preventDefault();
			window.location = event.target.href;
		}
	}
	window.addEventListener( 'click', handleWindowClick, false );
} )();
