
export function getWindowHeight() {
	return ( window.innerHeight || document.documentElement.clientHeight );
}

export function getWindowWidth() {
	return ( window.innerWidth || document.documentElement.clientWidth );
}

export function handleBodyClasses( toggle_class, remove_class ) {
	if ( document.body.classList.contains( remove_class ) ) {
		document.body.classList.remove( remove_class );
	}
	document.body.classList.toggle( toggle_class );
}
