// external libs
import { getWindowWidth } from '../helpers.js';
import { HammerSlider } from '../vendor/hammer-slider-edited.min.js';

export function buildSlider( el, breakpoints ) {
	const slider_type = ( el.getAttribute( 'data-slider-type' ) || 'slider' );
	const slides = el.querySelectorAll( '.base-slider__slide' );
	if ( slider_type === 'carousel' && slides.length < 2 ) {
		return;
	}

	const responsive_views = createResponsiveViews( el.getAttribute( 'data-slider-view-count' ) );
	const on_resize = [];
	let visible_slide_count = 1;
	if ( responsive_views !== null ) {
		visible_slide_count = setVisibleSlideCount( responsive_views );
		on_resize.push( () => {
			visible_slide_count = setVisibleSlideCount( responsive_views );
		} );
	}

	const slider_container = el.querySelector( '.base-slider__element' );
	const navigate_els = Array.from( el.querySelectorAll( '[data-slider-nav]' ) );
	const navigate_prev = el.querySelector( '[data-slider-prev]' );
	const navigate_next = el.querySelector( '[data-slider-next]' );

	let last_active_index = 0;
	// rewind is false -> infinite sliding
	const slider = HammerSlider(
		slider_container,
		{
			touchSpeed: 50,
			slideInterval: 2000,
			rewind: ( slider_type === 'slider' ),
			mouseDrag: false,
			cssPrefix: 'base-slider',
			dots: false,
			beforeSlideChange: ( new_index ) => {
				if ( slider_type === 'carousel' && typeof new_index !== 'undefined' ) {
					new_index = new_index % slides.length;
				}
				if ( navigate_els[0] ) {
					navHighlight( navigate_els, new_index );
				}
				if ( slider_type === 'slider' ) {
					const last_good_index = updateButtons( new_index );
					// only allow sliding if we're not at the end of the track
					if ( new_index > last_good_index ) {
						slider.moveTo( last_good_index );
						return false;
					}
				}
				return true;
			},
			afterSlideChange: ( index ) => {
				if ( index === last_active_index ) {
					return;
				}
				index = index % slides.length;
				handleSlideMedia();
				slider_container.setAttribute( 'data-slider-index', index );
				last_active_index = index;
			},
			onSetup: () => {
				navigate_prev.addEventListener( 'click', () => {
					slider.prev();
				} );
				navigate_next.addEventListener( 'click', () => {
					slider.next();
				} );
				if ( navigate_els[0] ) {
					navSetup( navigate_els );
					navHighlight( navigate_els, 0 );
				}
			}
		}
	);

	if ( slider_type === 'slider' ) {
		updateButtons( 0 );
		on_resize.push( updateButtons );
	}

	window.addEventListener( 'resize', () => {
		for ( let i = 0; i < on_resize.length; i++ ) {
			on_resize[i]();
		}
	} );

	// Used for keeping the slides in 'bounds'
	function createResponsiveViews( str ) {
		if ( !str ) {
			return null;
		}
		const obj = {};
		const arr = str.split( '|' );
		for ( let i = 0; i < arr.length; i++ ) {
			const val = arr[i].split( ':' );
			obj[val[0]] = val[1];
		}
		return obj;
	}
	function setVisibleSlideCount( views_obj ) {
		const viewport_width = getWindowWidth();
		const keys = Object.keys( views_obj );
		for ( let i = 0; i < keys.length; i++ ) {
			const key = keys[i];
			const breakpoint = breakpoints[key].width;
			if ( breakpoint === 'default' || viewport_width < breakpoint ) {
				return views_obj[key];
			}
		}
	}

	// Used for enabling / disabling the left & arrow arrows
	function updateButtons( current_index = last_active_index ) {
		const last_good_index = slides.length - visible_slide_count;
		toggleBtnUseable( navigate_prev, current_index === 0 );
		toggleBtnUseable( navigate_next, current_index >= last_good_index );
		return last_good_index;
	}
	function toggleBtnUseable( btn, test ) {
		if ( test ) {
			btn.classList.add( 'disabled' );
			btn.blur();
			return;
		}
		btn.classList.remove( 'disabled' );
	}

	// Nav functions
	function navHighlight( arr, index ) {
		const active_els = arr.filter( item => item.classList.contains( 'active' ) );
		if ( active_els.length > 0 ) {
			for ( let i = 0; i < active_els.length; i++ ) {
				active_els[i].classList.remove( 'active' );
			}
		}
		arr[index].classList.add( 'active' );
	}
	function navSetup( items ) {
		for ( let i = 0; i < items.length; i++ ) {
			const index = i;
			items[index].addEventListener( 'click', () => {
				slider.moveTo( index );
			} );
		}
	}

	// force Uncloak to lazy load slide images on slide change
	function handleSlideMedia() {
		if ( typeof window.uncloakInstance !== 'undefined' ) {
			window.uncloakInstance.forceProcess = true;
		}
	}
}
