import Viewer from 'viewerjs/dist/viewer.esm';

export function initViewer( container ) {
	const type = container.getAttribute( 'data-lightbox-type' );
	const button = container.querySelector( '.lightboxable__trigger' );
	const is_gallery = ( type === 'gallery' );
	// Used on carousel as otherwise viewer picks up the carousel thumbs
	const image_container = ( container.querySelector( '[data-lightbox-container]' ) || container );
	const viewer = new Viewer( image_container, {
		navbar: is_gallery,
		rotatable: 0,
		scalable: 0,
		title: 0,
		toggleOnDblclick: 0,
		toolbar: {
			zoomIn: 1,
			zoomOut: 1,
			oneToOne: 0,
			reset: 0,
			prev: is_gallery,
			play: 0,
			next: is_gallery
		},
		zIndex: 3,
		zoomRatio: 0.25,
		url( image ) {
			return image.dataset.lightboxUrl;
		}
	} );

	button.addEventListener( 'click', ( e ) => {
		e.preventDefault();
		viewer.view( image_container.getAttribute( 'data-slider-index' ) || 0 );
	} );
}
