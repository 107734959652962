import { getWindowHeight } from '../helpers.js';
import { hookAjaxForm } from './hook-ajax-form.js';

export function initAnnotations() {
	const els = document.querySelectorAll( '.annotate' );
	if ( !els[0] ) {
		return;
	}

	const states = {
		boxHide: 'annotate--box-hide',
		btnHide: 'annotate--btn-hide',
		editing: 'annotate--editing',
		processing: 'annotate--processing',
		filled: 'annotate--filled'
	};

	let hover_active = false;
	const all_annotates = [];
	for ( let i = 0; i < els.length; i++ ) {
		const annotate = els[i];
		const annotate_obj = {
			container: els[i],
			element: els[i].querySelector( '.annotate__element' )
		};
		// show / hide the annotate button on mouseover
		annotate.addEventListener( 'mouseover', handleBlockHover( annotate, false ) );
		annotate.addEventListener( 'mouseout', handleBlockHover( annotate ) );
		hookBtn( annotate );
		all_annotates.push( annotate_obj );
	}

	window.addEventListener( 'click', ( e ) => {
		let is_annotation = false;
		let node = e.target;
		while ( node ) {
			if ( node.classList && node.classList.contains( 'annotate__element' ) ) {
				is_annotation = true;
				break;
			}
			node = node.parentNode;
		}
		if ( is_annotation ) {
			return;
		}
		for ( let i = 0; i < all_annotates.length; i++ ) {
			all_annotates[i].container.classList.add( states.boxHide );
		}
	} );

	// Closest node calculation is tested by .annotate__element
	// But classes are applied to .annotate
	let last_closest_obj = all_annotates[0];
	window.addEventListener( 'scroll', () => {
		const wh = getWindowHeight();
		let closest_obj = all_annotates[0];
		let ymin = getDistFromMiddle( closest_obj.element, wh );
		for ( let i = 1; i < all_annotates.length; i++ ) {
			const obj = all_annotates[i];
			const dy = getDistFromMiddle( obj.element, wh );
			if ( dy < ymin ) {
				closest_obj = obj;
				ymin = dy;
			}
		}
		if ( last_closest_obj === closest_obj ) {
			return;
		}
		if ( !hover_active ) {
			if ( last_closest_obj ) {
				last_closest_obj.container.classList.add( states.btnHide );
			}
			closest_obj.container.classList.remove( states.btnHide );
		}
		last_closest_obj = closest_obj;
	} );

	function handleBlockHover( annotate, mouseout = true ) {
		return () => {
			if ( annotate === last_closest_obj.container ) {
				return;
			}
			hover_active = true;
			let el_to_hide = last_closest_obj.container;
			let el_to_show = annotate;
			if ( mouseout ) {
				hover_active = false;
				el_to_hide = annotate;
				el_to_show = last_closest_obj.container;
			}
			el_to_show.classList.remove( states.btnHide );
			el_to_hide.classList.add( states.btnHide );
		};
	}

	function hookBtn( el ) {
		const textbox = el.querySelector( '.annotate__comment' );
		const box_visibility_btn = el.querySelector( '.annotate__btn' );
		const save_form = el.querySelector( '[data-form-annotate-save]' );
		const save_form_button = save_form.querySelector( '.annotate__action-save' );
		const edit_btn = save_form.querySelector( '.annotate__action-edit' );
		const delete_form = el.querySelector( '[data-form-annotate-delete]' );
		const close_box_trigger = el.querySelector( '.annotate__close' );

		hookAjaxForm( save_form, save_form_button, () => {
			// on success
			textbox.setAttribute( 'readonly', '' );
			setTimeout( () => {
				el.classList.remove( states.processing );
				el.classList.remove( states.editing );
				el.classList.add( states.filled );
			}, 250 );
		}, () => {
			// on load
			el.classList.add( states.processing );
		} );

		hookAjaxForm( delete_form, delete_form.querySelector( 'button' ), () => {
			// on success
			el.classList.add( states.boxHide );
			el.classList.remove( states.filled );
			textbox.value = '';
			allowEdit();
		} );

		box_visibility_btn.addEventListener( 'click', () => {
			el.classList.toggle( states.boxHide );
			if ( !textbox.hasAttribute( 'readonly' ) && !el.classList.contains( states.boxHide ) ) {
				textbox.focus();
			}
		} );

		close_box_trigger.addEventListener( 'click', () => {
			el.classList.add( states.boxHide );
		} );

		edit_btn.addEventListener( 'click', () => {
			allowEdit();
			textbox.focus();
		} );

		function allowEdit() {
			textbox.removeAttribute( 'readonly' );
			el.classList.add( states.editing );
		}
	}
	function getDistFromMiddle( el, window_height ) {
		return Math.abs( 0.5 - el.getBoundingClientRect().top / window_height );
	}
}
